import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { MdExpandLess } from "react-icons/md"
import { Link as LinkScroll } from "react-scroll"

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      sanityCompanyInfo {
        phone
        logo {
          asset {
            fluid(maxWidth: 10) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
      allSanityServices {
        edges {
          node {
            title
            slug {
              current
            }
          }
        }
      }

      allSanityServices {
        edges {
          node {
            title
            slug {
              current
            }
          }
        }
      }
      allSanityAccounting {
        edges {
          node {
            title
            slug {
              current
            }
          }
        }
      }
      allSanityPages {
        edges {
          node {
            sidetype
            id
          }
        }
      }
    }
  `)

  return (
    <header>
      <Link to="/" className="logo_wrapper">
        <Img
          fluid={data.sanityCompanyInfo.logo.asset.fluid}
          alt="Zignifikant logo"
        />
      </Link>
      <div
        role="button"
        className="burger"
        onClick={burgerClicked}
        onKeyDown={burgerClicked}
      >
        <div></div>
        <div></div>
      </div>
      <nav>
        <div className="link_wrapper">
          {data.allSanityPages.edges.map(function(page) {
            if (page.node.id === "003a19e9-4e78-5c62-ad59-a63cf59cd0b7") {
              return (
                <Link activeClassName="active" to="/services/">
                  {page.node.sidetype}
                </Link>
              )
            }
          })}
          <MdExpandLess className="link_arrow" onClick={arrowClicked} />
        </div>
        <ul className="hidden_links">
          {data.allSanityServices.edges.map(function(link) {
            return (
              <li>
                <Link
                  activeClassName="active"
                  to={`/${link.node.slug.current}/`}
                >
                  {link.node.title}
                </Link>
              </li>
            )
          })}
        </ul>

        <div className="link_wrapper">
          {data.allSanityPages.edges.map(function(page) {
            if (page.node.id === "f04250e7-08ad-5a04-96b2-26f1261c7a3d") {
              return (
                <Link activeClassName="active" to="/insights/">
                  {page.node.sidetype}
                </Link>
              )
            }
          })}
          <MdExpandLess className="link_arrow" onClick={arrowClicked} />{" "}
        </div>
        <ul className="hidden_links">
          <li>
            <Link activeClassName="active" to={`/cases/`}>
              Cases
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to={`/nyheder/`}>
              Nyheder
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to={`/blog/`}>
              blog
            </Link>
          </li>
        </ul>

        <div className="link_wrapper">
          {data.allSanityPages.edges.map(function(page) {
            if (page.node.id === "8e21845b-bff4-5a83-9ee7-deafa6f3cd02") {
              return (
                <Link activeClassName="active" to="/automatisk-bogholderi/">
                  {page.node.sidetype}
                </Link>
              )
            }
          })}
          <MdExpandLess className="link_arrow" onClick={arrowClicked} />{" "}
        </div>
        <ul className="hidden_links">
          {data.allSanityAccounting.edges.map(function(link) {
            return (
              <li>
                <Link to={`/${link.node.slug.current}/`}>
                  {link.node.title}
                </Link>
              </li>
            )
          })}
        </ul>

        <div className="link_wrapper">
          {data.allSanityPages.edges.map(function(page) {
            if (page.node.id === "133553ec-403f-5452-bfa6-dfb08839f70e") {
              return (
                <Link activeClassName="active" to="/om-os/">
                  {page.node.sidetype}
                </Link>
              )
            }
          })}
          <MdExpandLess className="link_arrow" onClick={arrowClicked} />{" "}
        </div>
        <ul className="hidden_links">
          <li>
            <Link activeClassName="active" to={`/om-os/`}>
              Om os
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to={`/team/`}>
              Team
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to={`/moed-vores-medarbejdere/`}>
              Mød vores medarbejdere
            </Link>
          </li>
        </ul>

        <div className="link_wrapper">
          {data.allSanityPages.edges.map(function(page) {
            if (page.node.id === "e2323800-9342-596f-a107-de023a708ceb") {
              return (
                <Link activeClassName="active" to="/job/">
                  {page.node.sidetype}
                </Link>
              )
            }
          })}
        </div>

        <LinkScroll
          className="phone_button"
          activeClass="active"
          to="contact-form"
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
          style={{ cursor: "pointer" }}
        >
          Kontakt
        </LinkScroll>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

function burgerClicked() {
  document.querySelector("nav").classList.toggle("open_menu")
  document.querySelector(".burger").classList.toggle("open_burger")
}

export default Header

function arrowClicked(event) {
  let target = event.target
  let parent = target.parentElement
  let hiddenLinks = parent.nextSibling
  let height = hiddenLinks.scrollHeight
  const allHiddenLinks = document.querySelectorAll(".hidden_links")
  const allArrows = document.querySelectorAll(".link_arrow")

  allHiddenLinks.forEach(links => {
    links.style.maxHeight = "0px"
    links.classList.remove("active")
  })
  allArrows.forEach(arrow => {
    arrow.classList.remove("link_arrow_up")
  })

  if (hiddenLinks.classList.contains("active") === true) {
    hiddenLinks.style.maxHeight = "0px"
    hiddenLinks.classList.remove("active")

    target.classList.remove("link_arrow_up")

    // allHiddenLinks.forEach(links => {
    //   links.classList.remove("active")

    //   target.classList.remove("link_arrow_up")
    // })
  } else {
    hiddenLinks.style.maxHeight = height + "px"
    target.classList.add("link_arrow_up")

    hiddenLinks.classList.add("active")
  }
}
