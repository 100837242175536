import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Footer = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      sanityCompanyInfo {
        phone
        zipCode
        email
        cvr
        linkedIn
        facebook
        address
        logo {
          asset {
            fluid(maxWidth: 10) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
      allSanityAwards {
        edges {
          node {
            image {
              asset {
                fluid(maxWidth: 10) {
                  ...GatsbySanityImageFluid_noBase64
                }
              }
            }
            title
          }
        }
      }
      allSanityFooter {
        edges {
          node {
            footer1 {
              ... on SanityFooterSetting1 {
                Navn
                subpage {
                  ... on SanityPages {
                    slug {
                      current
                    }
                  }
                  ... on SanitySubPages {
                    slug {
                      current
                    }
                  }
                }
              }
              ... on SanityFooterSetting2 {
                Navn
                url
              }
            }
            footer2 {
              ... on SanityFooterSetting1 {
                Navn
                subpage {
                  ... on SanityPages {
                    slug {
                      current
                    }
                  }
                  ... on SanitySubPages {
                    slug {
                      current
                    }
                  }
                }
              }
              ... on SanityFooterSetting2 {
                Navn
                url
              }
            }
          }
        }
      }
    }
  `)

  return (
    <footer>
      <div className="left_wrapper">
        <Link to="/" className="logo_footer">
          <Img
            fluid={data.sanityCompanyInfo.logo.asset.fluid}
            alt="Zignifikant logo"
          />
        </Link>
        <p className="address">{data.sanityCompanyInfo.address}</p>
        <p className="address">{data.sanityCompanyInfo.zipCode}</p>
        <p className="cvr">CVR: {data.sanityCompanyInfo.cvr}</p>
        <div className="award_wrapper">
          {data.allSanityAwards.edges.map(function(edge) {
            return (
              <Img
                className="img-hover-effect"
                fluid={edge.node.image.asset.fluid}
                alt={edge.node.title}
              />
            )
          })}
        </div>

        <div className="copyright_desktop">
          © Zignifikant {new Date().getFullYear()}
          <br />
          <a className="hl" href="https://kodexcph.com/">
            Bygget af Kodex
          </a>
        </div>
      </div>
      <div className="right_wrapper">
        <div className="column_wrapper">
          <p>Zignifikant</p>
          {data.allSanityFooter.edges[0].node.footer1.map(function(link) {
            return (
              <div className="link_wrapper">
                {link.subpage && (
                  <Link to={`/${link.subpage.slug.current}/`}>{link.Navn}</Link>
                )}
                {link.page && (
                  <Link to={`/${link.page.slug.current}/`}>{link.Navn}</Link>
                )}
                {link.url && <a href={`${link.url}/`}>{link.Navn}</a>}
              </div>
            )
          })}
        </div>

        <div className="column_wrapper">
          <p>Udforsk</p>
          {data.allSanityFooter.edges[0].node.footer2.map(function(link) {
            return (
              <div className="link_wrapper">
                {link.subpage && (
                  <Link to={`/${link.subpage.slug.current}/`}>{link.Navn}</Link>
                )}
                {link.page && (
                  <Link to={`/${link.page.slug.current}/`}>{link.Navn}</Link>
                )}
                {link.url && <a href={`/${link.url}/`}>{link.Navn}</a>}
              </div>
            )
          })}
        </div>

        <div className="column_wrapper">
          <p>Ræk ud</p>

          <a
            className="phone_button"
            href={`tel:${data.sanityCompanyInfo.phone}`}
          >
            {`+45 ${data.sanityCompanyInfo.phone}`}
          </a>

          <a
            className="phone_button"
            href={`mailto:${data.sanityCompanyInfo.email}`}
          >
            {data.sanityCompanyInfo.email}
          </a>

          <a href={data.sanityCompanyInfo.linkedIn}>LinkedIn</a>

          <a href={data.sanityCompanyInfo.facebook}>Facebook</a>
        </div>

        <div className="copyright_mobile">
          {" "}
          © Zignifikant {new Date().getFullYear()}
          {` `}
          <br />
          <a className="hl" href="https://kodexcph.com/">
            {" "}
            Bygget af Kodex
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
