/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./style.scss"

// var WebFont = require("webfontloader")

// if (typeof window !== "undefined") {
//   WebFont.load({
//     typekit: {
//       id: "itd5wjf",
//     },
//   })
// }

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main onLoad={initiateAnimations}>{children}</main>
        <Footer></Footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

function initiateAnimations() {
  // allChildrenElements.classList.add("animation")
  // allChildrenElements.classList.add("animation--fade-up")

  // callback function to do animations
  const scrollImations = (entries, observer) => {
    entries.forEach(entry => {
      // only do animation if the element is fully on screen
      if (entry.isIntersecting && entry.intersectionRatio >= 0) {
        entry.target.classList.add("animation--visible")
      } else {
      }
    })
  }

  // create the observer
  const options = {
    threshold: 0.2,
  }
  const observer = new IntersectionObserver(scrollImations, options)

  // target the elements to be observed
  const animations = document.querySelectorAll(".animation")
  animations.forEach(animation => {
    observer.observe(animation)
  })
}

// function imageIsLoaded() {
//   initiateAnimations()
//   window.addEventListener("scroll", scrollFunctionImage)
// }

// function scrollFunctionImage() {
//   if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
//     document.querySelector(".postImage").classList.add("scaledIMG")
//   } else {
//     document.querySelector(".postImage").classList.remove("scaledIMG")
//   }
// }
